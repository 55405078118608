import StoSidebarItem from "../../scheduling/Settings/StoSidebarItem";
import {Collapse} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";

function PricingSidebarNavigationLevel4({activePanelType, setActivePanelType, setScrollToItem}) {
    const panelConfigurations = useSelector(state => state.pricing.panelConfigurations)
    const panelTypes = useSelector(state => state.panelTypes.panelTypes)

    const activePanelConfigurations = panelConfigurations.filter(panelConfiguration => panelTypes.find(pT => pT.id === panelConfiguration.panelTypeId && pT.active))

    const [collapsed, setCollapsed] = useState({})

    useEffect(() => {
        activePanelConfigurations?.forEach(panelType => {
            collapsed[panelType.name] = panelType.name in collapsed ? collapsed[panelType.name] : true
        })

        setCollapsed(collapsed)
        if (activePanelType === null) {
            setActivePanelType(activePanelConfigurations?.find(panelType => panelType))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePanelConfigurations]);

    function toggleCollapse(name) {
        setCollapsed({...collapsed, [name]: !collapsed[name]})
    }

    function scrollToItem(panelType, componentGroup) {
        setActivePanelType(panelType)
        setScrollToItem({
            panelType: panelType,
            componentGroup: componentGroup,
        })
    }

    function getSidebarContent() {
        return activePanelConfigurations?.map((panelConfiguration, index) => {
            return (
                <Fragment key={index}>
                    <StoSidebarItem name={panelConfiguration.name}
                                    toggleCollapse={() => toggleCollapse(panelConfiguration.name)}
                                    setSidebarItem={() => setActivePanelType(panelConfiguration)}
                                    isTopLevelSelectable
                                    collapse={collapsed[panelConfiguration.name]}
                                    activeItem={activePanelType?.name}/>
                    <Collapse in={collapsed[panelConfiguration.name]}>
                        {
                            getComponentGroups(panelConfiguration)
                        }
                    </Collapse>
                </Fragment>
            )
        })
    }

    function getGeneralContent() {
        return (
            <StoSidebarItem name={'General Configurations'}
                            setSidebarItem={() => scrollToItem({name: 'General Configurations'})}
                            isTopLevelSelectable
                            activeItem={activePanelType?.name}/>
        )
    }

    function getComponentGroups(panelConfiguration) {
        let componentGroupsPart1 = []

        if (panelConfiguration?.componentGroupsStructural) {
            componentGroupsPart1 = componentGroupsPart1.concat(panelConfiguration.componentGroupsStructural)
        }
        if (panelConfiguration?.componentGroupsPerformance) {
            componentGroupsPart1 = componentGroupsPart1.concat(panelConfiguration.componentGroupsPerformance)
        }
        let componentGroupsPart2 = []
        if (panelConfiguration?.componentGroupsWrapping) {
            componentGroupsPart2 = componentGroupsPart2.concat(panelConfiguration.componentGroupsWrapping)
        }
        if (panelConfiguration?.componentGroupsBuildingAttachments) {
            componentGroupsPart2 = componentGroupsPart2.concat(panelConfiguration.componentGroupsBuildingAttachments)
        }

        const startIndexPart2 = componentGroupsPart1.length + panelConfiguration?.componentGroupsDesign?.length

        return (<Fragment>
                {
                    componentGroupsPart1.map((componentGroup, i) => {
                        return (
                            <StoSidebarItem key={i} name={i + 1 + " " + componentGroup.name} toggleCollapse={null}
                                            setSidebarItem={() => scrollToItem(panelConfiguration.panelTypeId, componentGroup)}/>
                        )
                    })
                }
                {
                    componentGroupsPart2.map((componentGroup, i) => {
                        return (
                            <StoSidebarItem key={i} name={i + 1 + startIndexPart2 + " " + componentGroup.name}
                                            toggleCollapse={null}
                                            setSidebarItem={() => scrollToItem(panelConfiguration.panelTypeId, componentGroup)}/>
                        )
                    })
                }
            </Fragment>
        )
    }

    return (
        <Fragment>
            {getGeneralContent()}
            {getSidebarContent()}
        </Fragment>
    )
}

export default PricingSidebarNavigationLevel4
