import {makeStyles} from "@mui/styles";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {
    resetPanelSettingsToCompanyDefault,
    updateCompanyPanelSettings,
    updateSettings
} from "./settingsSlice";
import StoLengthInput from "../utils/components/StoLengthInput";
import StoCheckbox from "../utils/components/StoCheckbox";
import React, {useState} from "react";
import StoIconButton from "../utils/components/StoIconButton";
import ResetIcon from "../utils/icons/reset.svg";
import SaveIcon from "../utils/icons/save.svg";
import ResetDoneIcon from "../utils/icons/reset-done.svg";
import StoSnackbar from "../utils/components/StoSnackbar";
import StoDialog from "../utils/components/StoDialog";
import StoPrimaryButton from "../utils/components/StoPrimaryButton";

const useStyle = makeStyles(() => ({
    formular: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2rem',
        width: '52.2rem',
        gap: '1rem',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    padding: {
        height: "3rem",
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: "15rem",
    },
    rowTitle: {
        width: "13rem",
    },
    headerTitle: {
        width: "19rem",
        textAlign: "center",
    }
}))

function PanelSettings() {
    const classes = useStyle();

    const dispatch = useDispatch();
    const {projectId} = useParams();

    const panelSettings = useSelector(state => state.settings.settings)

    const [saveOpen, setSaveOpen] = useState(false)

    const [showSaveSnackbar, setShowSaveSnackbar] = useState(false)
    const [showResetSnackbar, setResetSaveSnackbar] = useState(false)

    const [resetValues, setResetValues] = useState(false)

    const unitSystem = useSelector(state => state.unitSystem.unitSystem)
    const companyId = useSelector(state => state.projects.companyId)
    const isAdmin = useSelector(state => state.projects.userRoles).some(role => role === 'admin:all')

    const [settings, setSettings] = useState({
        horizontalPanelSettings: panelSettings.horizontalPanelSettings,
        verticalPanelSettings: panelSettings.verticalPanelSettings,
        panelWindowDistance: panelSettings.panelWindowDistance,
        panelDepth: panelSettings.panelDepth,
        panelGap: panelSettings.panelGap,
        panelReturnDepth: panelSettings.panelReturnDepth,
        forceSlabToSlab: panelSettings.forceSlabToSlab,
        panelDebug: panelSettings.panelDebug
    })

    function updatePanelSettings(setting, value) {
        const panelSettings = {}
        panelSettings[setting] = value
        return dispatch(updateSettings(
            {
                projectId: projectId,
                settings: panelSettings
            }))
    }

    function updateHorizontalPanelSettings(property, value, minValue, maxValue, minValueProperty) {
        value = minValue ? Math.max(value, minValue) : value
        value = maxValue ? Math.min(value, maxValue) : value

        const horizontalSettings = {
            ...settings.horizontalPanelSettings,
            [property]: value,
        }

        if (minValueProperty) {
            horizontalSettings[minValueProperty] = Math.min(settings.horizontalPanelSettings[minValueProperty], value)
        }

        const newSettings = {
            ...settings,
            horizontalPanelSettings: horizontalSettings
        }

        setSettings(newSettings)
        updatePanelSettings("horizontalPanelSettings", horizontalSettings)
        setResetValues(!resetValues)
    }

    function updateVerticalPanelSettings(property, value, minValue, maxValue, minValueProperty) {
        value = minValue ? Math.max(value, minValue) : value
        value = maxValue ? Math.min(value, maxValue) : value

        const verticalSettings = {
            ...settings.verticalPanelSettings,
            [property]: value,
        }

        if (minValueProperty) {
            verticalSettings[minValueProperty] = Math.min(settings.verticalPanelSettings[minValueProperty], value)
        }

        const newSettings = {
            ...settings,
            verticalPanelSettings: verticalSettings
        }

        setSettings(newSettings)
        updatePanelSettings("verticalPanelSettings", verticalSettings)
        setResetValues(!resetValues)
    }

    function handleResetClick() {
        setResetSaveSnackbar(true)
        dispatch(resetPanelSettingsToCompanyDefault({projectId: projectId}))
    }

    function openSaveDialog(event) {
        event.stopPropagation()
        setSaveOpen(true)
    }

    const closeSaveDialog = (event) => {
        event.stopPropagation()
        setSaveOpen(false)
    }

    function handleSaveClick() {
        setShowSaveSnackbar(true)
        setSaveOpen(false)
        dispatch(updateCompanyPanelSettings({
            companyId: companyId,
            unitSystem: unitSystem,
            settings: settings
        }))
    }

    function getPanelSettingRow(title, property, minvalue, maxValueProperty, minValueProperty) {
        const maxValueHorizontal = maxValueProperty && settings && settings.horizontalPanelSettings ? settings.horizontalPanelSettings[maxValueProperty] : null
        const maxValueVertical = maxValueProperty && settings && settings.horizontalPanelSettings ? settings.verticalPanelSettings[maxValueProperty] : null

        return <div className={classes.row}>
            <Typography className={classes.rowTitle} variant="subtitle1">{title}</Typography>
            <StoLengthInput
                onBlur={(value) => updateHorizontalPanelSettings(property, Number(value), minvalue, maxValueHorizontal, minValueProperty)}
                value={settings && settings.horizontalPanelSettings ? settings.horizontalPanelSettings[property] : 0}
                theme={'padding'}
                padding={"0 0"}
                resetValues={resetValues}/>
            <StoLengthInput
                onBlur={(value) => updateVerticalPanelSettings(property, Number(value), minvalue, maxValueVertical, minValueProperty)}
                value={settings && settings.verticalPanelSettings ? settings.verticalPanelSettings[property] : 0}
                theme={'padding'}
                padding={"0 0"}
                resetValues={resetValues}/>
        </div>
    }

    return <div>
        <StoSnackbar open={showSaveSnackbar}
                     vertical={'top'} horizontal={'center'}
                     duration={3000}
                     onClose={() => setShowSaveSnackbar(false)}
                     message={"Panel settings successfully saved as company settings"}
        />
        <StoSnackbar open={showResetSnackbar}
                     vertical={'top'} horizontal={'center'}
                     duration={3000}
                     icon={ResetDoneIcon}
                     onClose={() => setResetSaveSnackbar(false)}
                     message={"Panel settings successfully reset to company settings"}
        />
        <div style={{alignItems: 'center', display: 'flex'}}>
            <Typography style={{paddingRight: '31rem'}} variant="h1">Panel Settings</Typography>
            <StoIconButton
                icon={ResetIcon} tooltip={"Reset panel settings and dimensions to company standard settings"}
                onClick={() => handleResetClick()}
            />

            {isAdmin && <StoIconButton
                icon={SaveIcon}
                tooltip={"Define current panel settings and dimensions as default company settings"}
                onClick={(event) => openSaveDialog(event)}
            />}
        </div>
        <div className={classes.formular}>
            <div className={classes.header}>
                <Typography className={classes.headerTitle} variant={"subtitle1"}>Horizontal</Typography>
                <Typography className={classes.headerTitle} variant={"subtitle1"}>Vertical</Typography>
            </div>
            {getPanelSettingRow("Min Width", "minPanelWidth", 0.05, "maxPanelWidth")}
            {getPanelSettingRow("Min Height", "minPanelHeight", 0.05, "maxPanelHeight")}
            {getPanelSettingRow("Max Width", "maxPanelWidth", 0.05, null, "minPanelWidth")}
            {getPanelSettingRow("Max Height", "maxPanelHeight", 0.05, null, "minPanelHeight")}
            {getPanelSettingRow("Max Oversize Width", "maxOversizePanelWidth", 0.05)}
            {getPanelSettingRow("Max Oversize Height", "maxOversizePanelHeight", 0.05)}
            <div className={classes.padding}/>
            <StoLengthInput title={"Opening Panel Edge Distance"}
                            onBlur={(value) => {
                                setSettings({...settings, panelWindowDistance: value})
                                updatePanelSettings("panelWindowDistance", value)
                            }}
                            value={settings.panelWindowDistance}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Panel Depth"}
                            onBlur={(value) => {
                                setSettings({...settings, panelDepth: value})
                                updatePanelSettings("panelDepth", value)
                            }}
                            value={settings.panelDepth}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Panel Gap Width"}
                            onBlur={(value) => {
                                setSettings({...settings, panelGap: value})
                                updatePanelSettings("panelGap", value)
                            }}

                            value={settings.panelGap}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoLengthInput title={"Corner Panel Depth"}
                            onBlur={(value) => {
                                setSettings({...settings, panelReturnDepth: value})
                                updatePanelSettings("panelReturnDepth", value)
                            }}
                            value={settings.panelReturnDepth}
                            theme={'padding'}
                            padding={"0 0"}/>
            <StoCheckbox title={"Force Slab to Slab"}
                         value={settings.forceSlabToSlab ? 'Checked' : 'Empty'}
                         onChange={(value) => {
                             setSettings({...settings, forceSlabToSlab: value})
                             updatePanelSettings("forceSlabToSlab", value)
                         }}
                         width={"20rem"}/>
            <StoCheckbox title={"Panel Debug Mode"}
                         value={settings.panelDebug ? 'Checked' : 'Empty'}
                         onChange={(value) => {
                             setSettings({...settings, panelDebug: value})
                             updatePanelSettings("panelDebug", value)
                         }}
                         width={"20rem"}/>

        </div>
        <StoDialog open={saveOpen} onClose={(event) => closeSaveDialog(event)}
                   title={""}
                   maxWidth={"36rem"}
                   content={
                       <div>
                           <Typography variant={"h1"} className={classes.deleteProjectTitle}>
                               {"Are you sure you want to override your company standard settings?"}</Typography>
                           <Typography variant={"subtitle1"} style={{paddingTop: "2rem"}}>
                               {"This will override the company standard settings for all projects in your company."}</Typography>
                       </div>
                   }
                   actions={<div className={classes.dialogActions}>

                       <StoPrimaryButton onClick={(event) => handleSaveClick(event)} title={"Save"}/>
                       <StoPrimaryButton onClick={(event) => closeSaveDialog(event)} title={"Cancel"} secondary/>

                   </div>}/>
    </div>
}

export default PanelSettings